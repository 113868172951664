export const APP_NAME = 'Obslend';

export const firebaseConfig = {
    apiKey: "AIzaSyC2ItM-X05PY7D_p47H99b7pa9TBZfoAcg",
    authDomain: "langara-app.firebaseapp.com",
    projectId: "langara-app",
    storageBucket: "langara-app.appspot.com",
    messagingSenderId: "896497767642",
    appId: "1:896497767642:web:3fc309846785574cf881e3",
    measurementId: "G-02ZGDCVNNS"
};